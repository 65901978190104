<template>
  <v-app>
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Page</span>
          <hr>
        </v-card-title>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  v-model="page.name"
                  :error="$v.page.name.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.page.name.$error">Name is required</span>
              <span class="text-danger" v-if="errors.name" >* {{ errors.name[0] }}</span>
            </v-col>


            <v-col cols="12">
              Description <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="page.description"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.page.description.$error">Description Field is required</span>
              <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              Status
              <v-switch
                  v-model="page.is_active"
                  :label="page.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="goBack"
        >
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            @click="createOrUpdate()"
            :loading="loading"
            v-if="checkIsAccessible('page', 'create') || checkIsAccessible('page', 'edit')"
        >
          {{edit ? "Update" : "Save"}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-app>

</template>

<script>
import PageService from "@/services/cms/page/PageService";
import {required, numeric} from "vuelidate/lib/validators";

const pageService = new PageService();

export default {
  validations: {
    page: {
        name: { required },
        description: { required },
        is_active: { required }
    }
  },
  data() {
    return{
      loading: false,
      edit: false,
      id: null,
      title: '',
      errors: [],
      page: {
        name: '',
        description: '',
        position: null,
        is_active: true,
    },
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            'Font', 'FontSize', 'FontColor', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'Image', 'NumberedList', 'BulletedList', 'TextColor', 'BGColor'
          ]
        ],
        extraPlugins: ['font', 'colorbutton'],
        height: "100vh",
        colorButton_enableMore: true,
      }
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    if(this.id) {
      this.title = 'Edit';
      this.edit = true;
      this.getPage();
    } else {
      this.title = 'Add';
      this.edit = false;
    }
  },
  methods:{
    goBack() {
      this.$tabs.close().then(() => {
        this.$tabs.open({ name: "page" });
      })
    },

    getPage () {
      pageService
          .show(this.id)
          .then(response => {
              this.page = response.data.page;
          }).catch(() => { });
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      pageService
          .create(this.page)
          .then(response => {
            this.$snotify.success("Page Created successfully");
            this.resetForm();
            this.loading = false;
            this.$router.push({name: "page"})
            this.$tabs.close();
          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });

    },
    update: function () {
      pageService
          .update(this.page.id, this.page)
          .then((response) => {
            this.$snotify.success("Page Updated successfully");
            this.resetForm();
            this.loading = false;
            this.$router.push({name: "page"})
            this.$tabs.close();
          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.errors = [];
      this.$v.$reset();
      this.page = {
        name: '',
        description: '',
        position: '',
        is_active: true,
      };
    },
  }
}
</script>
